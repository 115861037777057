<template>
  <div>
    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      :max-items="maximumItems"
      pagination
      :data="orders"
      :tableLoader="tableLoader"
    >

      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Package ID')}}</shipblu-th>
        <shipblu-th>{{$t('Agent')}}</shipblu-th>
        <shipblu-th>{{$t('Checkout Date')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'manager'">{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].trackingNumber">
            {{ data[indextr].order.tracking_number }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].pacakgeID">
            {{ Array(String(data[indextr].package.id).length - 1).join("*") + String(data[indextr].package.id).slice(-2) }} <br/>
          </shipblu-td>

          <shipblu-td :data="data[indextr].agent">
            {{ data[indextr].package_location.checkout_agent ? data[indextr].package_location.checkout_agent.first_name + data[indextr].package_location.checkout_agent.last_name : '' }} <br/>
          </shipblu-td>

          <shipblu-td :data="data[indextr].checkoutDate">
            {{ data[indextr].package_location.checkout_date }} <br/>
          </shipblu-td>

          <shipblu-td v-if="$store.state.AppActiveUser.userRole === 'manager'">
            <vs-dropdown
              vs-trigger-click
              class="dd-actions cursor-pointer"
            >
              <vs-button
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-more-horizontal"
                class="mr-2"
              >
              </vs-button>
              <vs-dropdown-menu style="width: 190px">
                <vs-dropdown-item
                  @click="confirmRemove(data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="XCircleIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Remove')}}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import AXIOS from '../../http/axios/index.js'
import i18nData from '../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      orders: [],
      tableLoader: false,
      selected: [],
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      package: {}
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.loadPackages()
    }
  },
  methods: {
    loadPackages () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/warehousing/${this.$route.params.type}/${this.$route.params.unitID}/order-packages/?offset=${this.offset}&limit=${this.maximumItems}`, 'get', null, true, 
        (response) => {
          this.orders = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    confirmRemove (data) {
      this.package = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure to remove this package?',
        accept: this.removePackage
      })
    },
    removePackage () {
      const packages = this.package.package.id
      AXIOS.delete(`api/v1/warehousing/${this.$route.params.type}/${this.$route.params.unitID}/packages/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        },
        data: {
          packages: [packages]
        }
      })
        .then(() => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Package'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadPackages()
          this.$vs.loading.close()
        })
        .catch((error) => {
          common.notifyResponseError(this, error)
          this.$vs.loading.close()
        })
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.loadPackages()
  }
}
</script>

<style scoped>
.vs-dialog {
  max-width: 530px;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
